.footer {
    border-top: none;
    padding-top: 150px;
}
.home-header {
    border-bottom: none;
}
.social {
    padding-top: 10px;
}
#cv-button {
    padding-top: 10px;
}
#my-title {
    padding-top: 5px;
}
