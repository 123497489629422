/* ==============================================
Template: vcard
Author:	Farbod Farhour
    - General setting
================================================ */
/* ------------------
    General setting
--------------------*/
html {
    overflow-y: scroll;
}
* {
    font-family: 'Raleway', Helvetica, Arial, sans-serif;
    font-weight: 300;
}
body {
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
h1 {
    font-size: 66px;
    line-height: 72px;
    margin-top: 48px;
    margin-bottom: 24px;
}
h2 {
    font-size: 41px;
    line-height: 48px;
    margin-top: 48px;
    margin-bottom: 24px;
}
h3 {
    font-size: 26px;
    line-height: 48px;
    margin-top: 24px;
    margin-bottom: 24px;
}
h4 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}
p, ul, ol, pre, table, blockquote {
    margin-top: 12px;
    margin-bottom: 12px;
}
hr {
    border: 1px solid;
    margin: -1px 0;
}
ul ul, ol ol, ul ol, ol ul {
    margin-top: 0;
    margin-bottom: 0;
}
b, strong, em, small, code {
    line-height: 1;
}
table {
    margin: 0;
    padding: 0;
}
sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
}
sub {
    top: 0.4em;
}
a {
    text-decoration: none;
    outline: none;
}
a:hover, a:visited, a:focus, a:active {
    text-decoration: none;
    outline: none;
}
.container {
    padding-right: 0;
    padding-left: 0;
}
.hover-animate {
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.font-weight-m {
    font-weight: 500;
}
.social ul {
    padding: 0;
    margin: 12px 0;
}
.social li {
    display: inline-block;
    list-style: none;
}
.vcard-icons {
    display: inline-block;
    font-size: 16px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 7px 7px;
    border-radius: 50%;
    border: 1px solid;
}
.cv-icon {
    display: inline-block;
    font-size: 16px;
    width: 240px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 7px 7px;
    border-radius: 15px;
    border: 1px solid;
}
.cv-icon:first-child, .vcard-icons:first-child {
    margin-left: 0;
}
.vcard-icons:hover {
}
.btn {
    border: 1px solid;
    padding: 0 20px;
    line-height: 38px;
    font-size: 16px;
    border-radius: 50px;
    margin-right: 10px;
    margin-top: 10px;
}
.width-td {
    width: 100px;
}
.width-td1 {
    width: 150px;
}
.title-resume {
    margin-bottom: 30px;
}
.title-contact {
    margin-bottom: 30px;
}
.title-blog {
    margin-bottom: 0;
}
.btn:hover {
}
.btn-color {
}
.btn-color:hover {
}
.block-grey {
    width: 100%;
    border: 1px solid;
    padding: 30px;
}
.block-grey table {
    width: 100%;
}
.block-grey table td {
    line-height: 16px;
    padding-bottom: 18px;
}
.border-bottom {
    border-bottom: 1px solid;
}
.border-top {
    border-top: 1px solid;
}
.border-left {
    border-left: 1px solid;
}
.border-right {
    border-right: 1px solid;
}
.padding-block {
    padding-top: 48px;
    padding-bottom: 48px;
}
#wrapper {
    border: 1px solid;
    margin: 25px auto;
    width: 1230px;
}
.scrollToTop {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    text-align: center;
    position: fixed;
    bottom: 100px;
    right: 0;
    display: none;
}
.wrapper-card .head .logo-page {
    margin-right: 0;
    float: right;
}
.profile .head, .resume .head, .portfolio-header .head, .blog .head, .contact .head {
    border-top: 1px solid;
}
#contents .blog .post {
    border: 0;
    padding-bottom: 0;
}
#contents .btn-center .btn{
    margin-right: 0;
    margin-top: 0;
}
#contents .portfolio-section .btn-center .btn{
    margin-top: 15px;
}
@media (min-width: 1200px) {
    .my-col-lg-5 {
        width: 35.66666667%;
    }
}
/* ------------------
    Animated setting
--------------------*/
.animated {
    visibility: hidden;
}

.visible {
    visibility: visible;
}
/* ------------------
    Home header
--------------------*/
.home-header .photo {
    border-radius: 50%;
    border: 1px solid #6e6e6e;
    width: 232px;
    height: 232px;
    overflow: hidden;
    display: inline-block;
}
.home-header .photo img {
    width: 232px;
    height: 232px;
}
.home-header .title {
    text-transform: uppercase;
    margin-bottom: 0;
}
.home-header .sub-title {
    font-weight: 300;
    margin: 0;
    line-height: 34px;
}
.home-header .full-length-photo {
    margin-top: 24px;
}
/* ------------------
    Footer section
--------------------*/
.footer {
    line-height: 36px;
    padding: 17px 0;
    border-top: 1px solid;
}
.footer a {
}
.footer .vcard-icons {
}
.footer a:hover {
}
.footer a:hover .vcard-icons {
}
.footer .copyright {
}
/* ------------------
    loading animation
--------------------*/
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -100px;
    width: 200px;
    height: 200px;
    background: url("../img/loading-circle.gif") center no-repeat;
    background-size: 50%;
    text-align: center;
}
