/* ==============================================
Template: vcard
Author:	Farbod Farhour
    - General setting
================================================ */
/* ------------------
    General setting
--------------------*/
body {
    color: #000000;
    background-color: #f5f5f5;
}
.cv-icon, .vcard-icons {
    color: #ffffff;
    border-color: #068cd6;
    background-color: #00b6f9;
}
.cv-icon:hover, .vcard-icons:hover{
    background-color: #0089d5;
    color: #ffffff;
}
.btn{
    background-color: #f5f5f5;
    color: #000000;
    border-color: #d1d1d1;
}
.btn:hover{
    border-color: #068cd6;
    background-color: #00b6f9;
    color: #ffffff;
}
.btn-color{
    border-color: #008fd9;
    background-color: #00b6f9;
    color: #ffffff;
}
.btn-color:hover{
    background-color: #008fd9;
    color: #ffffff;
}
.block-grey{
    background-color: #fdfdfd;
    border-color: #d1d1d1;
}
.border-bottom{
    border-bottom-color: #ededed;
}
.border-top {
    border-top-color: #ededed;
}
.border-left {
    border-left-color: #ededed;
}
.border-right {
    border-right-color: #ededed;
}
#wrapper{
    border-color: #c6c6c6;
    background-color: #ffffff;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}
.profile .head, .resume .head, .portfolio-header .head, .blog .head, .contact .head {
    border-top-color: #C6C6C6;
    border-bottom-color: #ededed;
}
.scrollToTop{
    color: #ffffff;
    border-color: #068cd6;
    background-color: #00b6f9;
}
.scrollToTop:hover{
    background-color: #0089d5;
    color: #ffffff;
}
/* ------------------
    loading
--------------------*/
.loading {
    background-color: #ffffff;
}
/* ------------------
    Footer section
--------------------*/
.footer{
    border-top-color: #C6C6C6;
}
.footer a{
    color: #000000;
    font-size: 110%;
}
.footer .vcard-icons{
}
.footer a:hover{
    color: #00b6f9;
}
.footer a:hover .vcard-icons{
    border-color: #068cd6;
    background-color: #0089d5;
    color: #ffffff;
}
.footer .copyright{
    color: gray;
}
